<template>
    <table class="table table-sm table-bordered align-top" >
        <thead class="bg-dark text-white">
            <tr>
                <th class="align-top" rowspan="2">No</th>
                <th>Nama Umum</th>
                <th>Nama Latin</th>
                <th>Nama Perusahaan</th>
                <th v-for="(header, key_header) in reslistlaporan.header">{{header.satuan}}</th>
            </tr>
        </thead>
        <tbody>
            <template>
                <tr v-if="loadingTable">
                    <td colspan="6"><i class="fa fa-spin fa-refresh"></i> Loading...</td>
                </tr>
                <tr v-for="(listlaporan, key_laporan) in reslistlaporan.list" v-else>
                    <td>
                        {{key_laporan+1}}
                    </td>
                    <td>{{listlaporan.t_nama}}</td>
                    <td>{{listlaporan.t_nama_latin}}</td>
                    <td>{{listlaporan.p_nama}}</td>
                    <td v-for="(header, key_header) in reslistlaporan.header">
                        <div v-for="(satuan, key_satuan) in listlaporan.satuan" v-if="satuan.satuan_id == header.id">
                            {{satuan.jumlah}}
                        </div>
                    </td>
                </tr>
            </template>
        </tbody>
    </table>
</template>
<script>
    const datalaporan = [];
    export default {
        name: "ExcelLaporanDataBenihPerusahaan",
        data() {
            return {
                reslistlaporan: datalaporan,
                // nib: this.$route.params.nib,
                paging: [],
                show_data_to: "",
                show_data_from: "",
                total_data: "",
                is_pemasukan: "",
                start: "",
                end: "",
                status: "",
                excel_url:"",
                nib: "9120405732598",
                show_tables: false,
                filter_status: [
                    {
                        value: "proses",
                        label: "Proses Verifikasi",
                    },
                    {
                        value: "menunggu perbaikan",
                        label: "Menunggu Perbaikan",
                    },
                    {
                        value: "selesai",
                        label: "Selesai",
                    },
                    {
                        value: "dibatalkan",
                        label: "Dibatalkan",
                    },
                ],
                filter_tipe: [
                    {
                        value: "1",
                        label: "Pemasukkan Benih Varietas",
                    },
                    {
                        value: "0",
                        label: "Pengeluaran Benih Varietas",
                    },
                ],
                curent_page: '',
                loadingTable: false,
            };
        },
        methods: {
            showData(){
                this.loadingTable = true;
                axios
                    .get(this.apiLink + "api/laporan/rekap_bulanan", {
                        params:{
                            nib: this.nib,
                            is_pemasukan: this.is_pemasukan,
                            start: this.start,
                            end: this.end,
                            status: 'selesai',
                        },
                        headers: {
                            Authorization: "Bearer " + this.access_token,
                        },
                    })
                    .then((response) => {
                        var res_laporan = response.data;
                        var res_laporan_data = res_laporan.data;
                        // console.log(res_laporan_data);
                        if (res_laporan.meta.code != "200") {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: res_laporan.data.message,
                            });
                        } else {
                            this.loadingTable = false;
                            this.show_tables = true;
                            this.reslistlaporan = res_laporan_data;
                            this.excel_url = res_laporan_data.excel;
                            // this.paging = res_laporan_data.list.links;
                            // this.show_data_from = res_laporan_data.from;
                            // this.show_data_to = res_laporan_data.list.to;
                            // this.total_data = res_laporan_data.list.total;
                            // this.curent_page = res_laporan_data.list.current_page;
                        }
                    });
            },
            getExcel(){
                var win = window.open(this.excel_url, '_blank');
                if (win) {
                    //Browser has allowed it to be opened
                    win.focus();
                } else {
                    //Browser has blocked it
                    alert('Please allow popups for this website');
                }
            }
        },
        beforeCreate() {
        },
        mounted() {
            $('.no-readonly').prop('readonly', false);
        }
    };
</script>